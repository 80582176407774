class Popup {

    handler=null

    constructor(viewer, options){
        this.$viewer = viewer
        this.$options = options
        this.init()
    }

    init(){
        let viewer = this.$viewer
        this.handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
        viewer.clock.onTick.addEventListener(() => {
            
        })
    }

    render(){}

    destroy(){}

    createHtml(header, content){
        let html = `<div>html test</div>`
    }



}

export default Popup